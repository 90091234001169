import { Component, OnInit } from '@angular/core';
import { JudgeExample } from 'src/app/models/judge-example';
import * as Parse from 'parse';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss']
})
export class TestingComponent implements OnInit {

  public currentExample: JudgeExample;
  public responded = false;
  public correctAnswer = false;

  constructor(
  ) { }

  ngOnInit(): void {
    this.updateComponent();
  }

  public updateComponent(): void{
    this.responded = false;
    this.correctAnswer = false;
    this.currentExample = new JudgeExample();
  }

  public setResponse(value: string): void{
    this.correctAnswer = (value === this.currentExample.getResult());
    this.responded = true;
    this.notifyServer();
  }

  private notifyServer(): void{
    Parse.Cloud.run('SaveResult', {
      code: this.currentExample.getCode(),
      id: this.currentExample.getId(),
      answer: this.correctAnswer
    }).then(r => {
      console.log(r);
    });
  }

}
