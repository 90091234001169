<div class="vote vote-color-{{vote}}" fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
    <mat-icon *ngIf="vote === 'B'" class="left">flag</mat-icon>
    <mat-icon *ngIf="vote === 'V'" class="view">visibility_off</mat-icon>
    <mat-icon *ngIf="vote === 'R'" class="right">flag</mat-icon>
    <mat-icon *ngIf="vote === 'N'" class="no">cancel</mat-icon>
  </div>

  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
    <div class="mat-body-2" transloco="votes.{{vote}}"></div>
  </div>
  
</div>