export class JudgeExample {

  private judgePanel = [ null, null, null, null, null];
  private voteTypes = ['R', 'B', 'N', 'V'];
  private results = {
    R: 0,
    B: 0,
    N: 0,
    V: 0
  };

  constructor(
  ) {
    const selection = Math.floor(Math.random() * 5);
    this.judgePanel[selection] = this.getVote(true);
    for (let i = 0; i < 5; i++){
      let vote = this.judgePanel[i];
      if (vote === null){
        vote = this.getVote();
        this.judgePanel[i] = vote;
      }
      this.results[vote]++;
    }
  }

  public getJudgeVote(index: number): string{
    return this.judgePanel[index];
  }

  private getVote(forceVote: boolean = false): string {
    let selection = Math.floor(Math.random() * 4);
    if (forceVote){
      selection =  Math.floor(Math.random() * 2);
    }
    return this.voteTypes[selection];
  }

  public getResult(): string{
    if (this.results.R > this.results.B && this.results.R > this.results.N){
      return 'R';
    }
    if (this.results.B > this.results.R && this.results.B > this.results.N){
      return 'B';
    }
    return 'N';
  }

  public getCode(): string{
    return this.judgePanel.join('');
  }

  public getId(): string{
    return `${this.results.R}R${this.results.B}B${this.results.N}N${this.results.V}V`;
  }

}
