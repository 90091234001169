<section fxLayout="column" fxLayoutAlign="center stretch" fxLayout.gt-xs="row" fxLayoutGap="20px">
  
  <mat-card fxLayout="column" fxFlex="100" fxFlex.gt-xs="70" fxFlex.gt-sm="60" fxLayoutGap="20px">
    
    <div fxLayout="row" fxFlex="33">
      <app-judge [vote]="currentExample.getJudgeVote(0)" fxFlex="45"></app-judge>
      <div fxFlex="10"></div>
      <app-judge [vote]="currentExample.getJudgeVote(1)" fxFlex="45"></app-judge>
    </div>
    
    <div fxLayout="row" fxFlex="33">
      <div fxFlex="25"></div>
      <app-judge [vote]="currentExample.getJudgeVote(2)" fxFlex="50"></app-judge>
      <div fxFlex="25"></div>
    </div>
    
    <div fxLayout="row" fxFlex="33">
      <app-judge [vote]="currentExample.getJudgeVote(3)" fxFlex="45"></app-judge>
      <div fxFlex="10"></div>
      <app-judge [vote]="currentExample.getJudgeVote(4)" fxFlex="45"></app-judge>
    </div>
  </mat-card>


  <mat-card *ngIf="!responded" fxLayout="column" fxFlex="100" fxFlex.gt-xs="30" fxLayoutGap="10px" fxLayoutAlign="center stretch">
    <div class="mat-h2" transloco="application.whoIsScoring"></div>

    <div fxLayout="row" fxLayout.gt-xs="column" fxLayoutGap="20px" fxLayoutAlign="center stretch">

      <button mat-raised-button class="vote-color-R" transloco="votes.R" (click)="setResponse('R')"></button>

      <button mat-raised-button class="vote-color-B" transloco="votes.B" (click)="setResponse('B')"></button>

      <button mat-raised-button class="vote-color-N" transloco="votes.N" (click)="setResponse('N')"></button>

    </div>

  </mat-card>


  <mat-card *ngIf="responded" fxLayout="column" fxFlex="100" fxFlex.gt-xs="30" fxLayoutGap="5px" fxLayoutAlign="center stretch">
    <div *ngIf="correctAnswer" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center center">
      <mat-icon>verified</mat-icon>
      <div class="mat-h2" transloco="messages.success"></div>
    </div>

    <div *ngIf="!correctAnswer" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center center">
      <mat-icon>do_disturb_alt</mat-icon>
      <div class="mat-h2" transloco="messages.error"></div>
      <div class="mat-h4" transloco="messages.correctAnswerIs"></div>
      <div class="mat-h2" transloco="votes.{{currentExample.getResult()}}"></div>
    </div>

    <button mat-raised-button color="primary" transloco="messages.otherExample" (click)="updateComponent()"></button>
  </mat-card>

</section>
