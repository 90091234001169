<div fxLayout="row">

  <div fxFlex>
    <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxFlex class="mat-body-1" transloco="application.name"></div>

      <mat-icon>language</mat-icon>

      <mat-select class="mat-body-1" [value]="selectedLanguage" (valueChange)="onLanguageChanged($event)">
        <mat-option *ngFor="let lang of languages" [value]="lang.id">
          {{ lang.name }}
        </mat-option>
      </mat-select>
      
    </mat-toolbar>
    <router-outlet></router-outlet>
    <div class="copyright">
      <small transloco="messages.copyright"></small>
    </div>
  </div>
</div>