import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-judge',
  templateUrl: './judge.component.html',
  styleUrls: ['./judge.component.scss']
})
export class JudgeComponent implements OnInit {

  @Input() vote = 'N';

  constructor() { }

  ngOnInit(): void {
  }

}
