import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'MTSDA Judge Analyzer';
  selectedLanguage = 'en';
  languages = [
    { id: 'es', name: 'Español' },
    { id: 'en', name: 'English' },
    { id: 'pt', name: 'Português'}
  ];

  constructor(
    private translocoService: TranslocoService
  ){
    this.translocoService.setDefaultLang('en');
    const defLang = localStorage.getItem('defaultLanguage') || 'en';
    this.applyLanguage(defLang);
  }

  private applyLanguage(defLang: string): void{
    this.selectedLanguage = defLang;
    localStorage.setItem('defaultLanguage', defLang);
    this.translocoService.setActiveLang(defLang);
  }

  onLanguageChanged($event): void{
    this.applyLanguage($event);
  }
}
